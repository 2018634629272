import React, { useEffect } from "react";
import SbEditable from "storyblok-react";

import { useParams } from "react-router-dom";

import { gql } from "apollo-boost";
import { Query } from "react-apollo";
import Components from "./index";

const _ = require("lodash");

export default () => {
  const { slug } = useParams();

  const query = gql`
        {
            CourseItem(id: "cursos/${slug}") {
                id
                content {
                    sidebar_hidden
                    related_courses {
                      content 
                      fullSlug
                      slug
                    }
                    campus_items
                    metodos
                    component
                    content
                    modalities
                    thumbnail {
                        filename
                        alt
                    }
                    title
                    body
                    redirect
                    redirect_url
                }
            }
        }
    `;

  let courseTitle = "Carregando...";

  useEffect(() => {
    setTimeout(() => {
      document.title = `Graduação - PUCPR - ${courseTitle}`;
    }, 1000);
  });

  return (
    <Query query={query}>
      {(result) => {
        if (result.loading) return null;
        if (result.error) return null;

        const course = result.data.CourseItem;
        if (!course) return "Curso não encontrado!";

        courseTitle = course.content.title;

        return (
          <SbEditable content={course.content}>
            <div>
              {course.content.body.map((blok) =>
                Components({
                  ...blok,
                  isPage: false,
                  hasCourse: true,
                  course: _.omit(course.content, "body"),
                })
              )}
            </div>
          </SbEditable>
        );
      }}
    </Query>
  );
};
