import React from "react";
import { AiOutlineEye, AiOutlineMail } from "react-icons/ai";
import { BsTelephone, BsWindow } from "react-icons/bs";

import Container from "../../Common/Container";

import { Background, Detail, FooterContainer, FooterContentWrapper, FooterHeader, FooterInformationIcon, FooterLinksWrapper, FooterText, Span } from "./styles";

export default function Footer4d() {

  return (
    <Background>
      <Container>
        <FooterContainer>
          <FooterHeader>
            <Detail />
            <FooterText>Graduação 4D</FooterText>
            <Detail />
          </FooterHeader>

          <FooterContentWrapper>
            <FooterText>Além</FooterText>
            <FooterText>das</FooterText>
            <AiOutlineEye color="#8A0538" size={40} />
            <FooterText>perspectivas</FooterText>
          </FooterContentWrapper>

          <FooterLinksWrapper>
            <Span>
              Mais informações:
            </Span>

            <FooterInformationIcon>
              <AiOutlineMail size={30} />
              E-mail: <a href="mailto:graduacao4d@pucpr.br">graduacao4d@pucpr.br</a>
            </FooterInformationIcon>

            <FooterInformationIcon>
              <BsTelephone size={30} />
              Telefone: 41985061944
            </FooterInformationIcon>

            <FooterInformationIcon>
              <BsWindow size={30} />
              Site:
              <a
                href="https://graduacao.pucpr.br/graduacao-4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                graduação-4d
              </a>
            </FooterInformationIcon>

          </FooterLinksWrapper>
        </FooterContainer>
      </Container>
    </Background>
  )
}