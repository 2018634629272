import Components from './index'
import SbEditable from 'storyblok-react'
import React from 'react'
const _ = require('lodash')

class Page extends React.Component {
  constructor() {
    super();

    this.state = {
      pageState: {},
    }
  }

  getPageState(key, defaultValue) {
    if (key) {
      return _.get(this.state.pageState, key, defaultValue)
    }

    return this.state.pageState
  }

  setPageState(newPageState) {
    this.setState({
      pageState: {
        ...this.state.pageState,
        ...newPageState
      },
    })
  }

  componentDidMount() {
    document.title = `Graduação - PUCPR - ${this.props.content.name}`
    document.page = () => this.state.pageState
  }

  render() {
    let context = this.props.content.context

    if (context) {
      context = context.map(item => _.omit(item, ['_editable', '_uid']))
      context = _.keyBy(context, 'component')
    }

    return (
      <SbEditable content={ this.props.content }>
        <div>
          { this.props.content.body.map((blok) =>
            Components({
              ...blok,
              setPageState: this.setPageState.bind(this),
              getPageState: this.getPageState.bind(this),
              isPage: true,
              hasCourse: false,
              course: null,
              context,
            })
          ) }
        </div>
      </SbEditable>
    )
  }
}

export default Page
